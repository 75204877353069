
import { Component, Vue } from 'vue-property-decorator';
import SectionCard from '@/components/SectionCard.vue'; // @ is an alias to /src
import RouteTitle from '@/components/RouteTitle.vue';
import UIUtil from '@/utils/ui.util';
import RouterUtil from '@/utils/router.util';
import { RouteConfig } from 'vue-router';
import AlertSiaNew from '@/components/AlertSiaNew.vue';

@Component({
  components: {
    RouteTitle,
    SectionCard,
    AlertSiaNew,
  },
})
export default class Home extends Vue {
  private sections: RouteConfig[] = [];

  get appTitle(): string {
    return this.$store.getters.fullAppTitle;
  }

  get realtimeUrl(): string {
    return process.env.VUE_APP_REALTIME_URL;
  }

  get showNewSIAMessage(): boolean {
    return process.env.VUE_APP_SHOW_NEW_SIA_MESSAGE === 'true';
  }

  generateSrc(fileName: string): string {
    return UIUtil.generateImagePathbyFileName(fileName);
  }

  getCardsData(): void {
    const routes = this.$router.options.routes;
    const cards = ['/audiencia-multiplataforma', '/audiencia-de-tv-oficial', '/audiencia-digital', '/outras-medicoes'];

    cards.forEach(card => {
      const r = RouterUtil.getRouteByPath(card, routes);
      if (r) this.sections.push(r);
    });
  }

  mounted(): void {
    this.getCardsData();
  }

}
